import { Component, Prop } from 'vue-property-decorator'
import { DialogComponent } from 'vue-modal-dialogs'
import BasicInput from '@/components/inputs/input/BasicInput.vue'
import { Template } from '@/domain/entities/Template'
import Button from '@/components/button/Button.vue'

@Component({
  components: {
    BasicInput,
    Button
  }
})
export default class SendTest extends DialogComponent<boolean> {
  private email = ''
  @Prop({ required: true, type: Template }) readonly template: Template;

  private sendTest () {
    this.email.split(',').forEach((email) => {
      this.$store.dispatch(
        'sendTest',
        {
          emailAddress: email.trim(),
          template: this.template
        }
      )
    })

    this.$close(true)
  }

  private preventClosing (event: Event) {
    event.preventDefault()
    event.stopPropagation()
  }
}
