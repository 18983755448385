import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class BasicInput extends Vue {
  @Prop({ required: false, type: String })
  private value: string

  @Prop({ required: false, type: String })
  readonly label: string

  @Prop({ required: false, type: String, default: 'text' })
  readonly inputType: string

  @Prop({ required: false, type: Boolean, default: false })
  readonly required: boolean

  get hasLabel (): boolean {
    return !!this.label
  }

  @Watch('value')
  private onValueChanged (value: string) {
    this.$emit('change', value)
  }
}
