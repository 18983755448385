import { Component, Prop } from 'vue-property-decorator'
import { DialogComponent } from 'vue-modal-dialogs'
import { Template } from '@/domain/entities/Template'
import Button from '@/components/button/Button.vue'

@Component({
  components: {
    Button
  }
})
export default class DeleteTemplate extends DialogComponent<boolean> {
  @Prop({ required: true, type: Template }) readonly template: Template;

  private deleteTemplate () {
    this.$store.dispatch(
      'deleteTemplate',
      { template: this.template }
    )
      .then(() => {
        this.$store.dispatch('getTemplates')
        this.$close(true)
      })
      .catch(() => this.$close(true))
  }

  private preventClosing (event: Event) {
    event.preventDefault()
    event.stopPropagation()
  }
}
