import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/button/Button.vue'
import { Template } from '@/domain/entities/Template'
import Header from '@/components/header/Header.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import SendTest from '@/components/modals/send-test/SendTest.vue'
import DeleteTemplate from '@/components/modals/delete-template/DeleteTemplate.vue'
import { create } from 'vue-modal-dialogs'

const SendTestModal = create(SendTest, 'template')
const DeleteTemplateModal = create(DeleteTemplate, 'template')

@Component({
  components: {
    Button,
    Header,
    Sidebar
  }
})
export default class IndexTemplate extends Vue {
  private searchString = ''

  get templates (): Template[] {
    return this.$store.state.template.templates.filter((template: Template) => {
      return template.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
        template.subject.toLowerCase().includes(this.searchString.toLowerCase()) ||
        (
          template.messageType !== undefined &&
          template.messageType.toLowerCase().includes(this.searchString.toLowerCase())
        )
    })
  }

  created () {
    this.$store.dispatch('getTemplates')
  }

  private showSendTestModal (template: Template) {
    SendTestModal(template)
  }

  private showConfirmDeleteModal (template: Template) {
    DeleteTemplateModal(template)
  }
}
